// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-gift-card-jsx": () => import("./../../../src/pages/gift-card.jsx" /* webpackChunkName: "component---src-pages-gift-card-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-requests-jsx": () => import("./../../../src/pages/requests.jsx" /* webpackChunkName: "component---src-pages-requests-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-shop-art-jsx": () => import("./../../../src/pages/shop/art.jsx" /* webpackChunkName: "component---src-pages-shop-art-jsx" */),
  "component---src-pages-shop-fiction-jsx": () => import("./../../../src/pages/shop/fiction.jsx" /* webpackChunkName: "component---src-pages-shop-fiction-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-shop-merch-jsx": () => import("./../../../src/pages/shop/merch.jsx" /* webpackChunkName: "component---src-pages-shop-merch-jsx" */),
  "component---src-pages-shop-nonfiction-jsx": () => import("./../../../src/pages/shop/nonfiction.jsx" /* webpackChunkName: "component---src-pages-shop-nonfiction-jsx" */),
  "component---src-pages-shop-pickles-jsx": () => import("./../../../src/pages/shop/pickles.jsx" /* webpackChunkName: "component---src-pages-shop-pickles-jsx" */),
  "component---src-pages-shop-rare-jsx": () => import("./../../../src/pages/shop/rare.jsx" /* webpackChunkName: "component---src-pages-shop-rare-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-product-page-jsx": () => import("./../../../src/templates/product-page.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */)
}

